import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import axios from 'axios';

function VimeoEmbed(props) {

  const { videoID, openVideoModal, setOpenVideoModal, setShowContentModal, setModalVideoInfo, videoDescription } = props;
  const [videoInfo, setVideoInfo] = useState(null);
  const svg_play = <svg xmlns="https://www.w3.org/2000/svg" fill="currentColor" height="48" width="48"><path d="M19.15 32.5 32.5 24l-13.35-8.5ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/></svg>;





  useEffect(() => {
    axios.get(`https://vimeo.com/api/v2/video/${videoID}.json`).then(res => {

      if (res && res.data && res.data[0]) {
        setVideoInfo(res.data[0]);
        console.log(res.data[0]);
      } else {
        console.log(' ')
        console.log('ERROR')
      }
    })
  }, []);


  function openThisVideo() {
    if (videoInfo && videoInfo != '') {
      setModalVideoInfo(videoInfo)
      setOpenVideoModal(true)
      setShowContentModal(true)
    }
  }


  return videoInfo && videoInfo != ''? (
    <>
      <div className="group w-full video-box h-full" onClick={ () => { openThisVideo() }}>


          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 1 }}
            className={`relative overflow-hidden`}
          >

              <div className='w-full relative flex items-center justify-center'>
                <img src={ videoInfo?.thumbnail_large? videoInfo.thumbnail_large : '' } className="object-cover w-full h-full" />
                
                <div className='def-transition absolute text-white opacity-75 scale-125 group-hover:scale-150 group-hover:opacity-100 bg-gray-800 bg-opacity-50 group-hover:bg-opacity-70 rounded-full'>
                  {svg_play}
                </div>
              </div>



              <p className='def-transition font-medium responsive-text px-3 py-2 overflow-ellipsis'>
                { videoInfo?.title? videoInfo.title : '' }
              </p>

              <div className='def-transition overflow-hidden border-t mx-1 px-3 py-3 border-gray-200 self-stretch'>
                  <p className='responsive-text-small leading-6' dangerouslySetInnerHTML={{__html: videoDescription? videoDescription : ''}}></p>
              </div>

              <script src="https://player.vimeo.com/api/player.js"></script>

          </motion.div>
          


      </div>


    </>
  ) : null;
}


{/* 

 */}


export default VimeoEmbed